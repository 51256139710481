<template>
  <div class="manage-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>持续体糖监测管理</Breadcrumb-item>
        <Breadcrumb-item>体糖详情</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="content-panel">
        <div class="flex">
          <div class="flex-item">
            <div class="flex-item-title">会员信息</div>
            <div class="flex-item-body">
              <div class="item">
                <div class="item-label">姓名</div>
                <div class="item-value">{{ summary.name }}</div>
              </div>
              <div class="item">
                <div class="item-label">性别</div>
                <div class="item-value">
                  {{ summary.sex === 0 ? '男' : '女' }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">年龄</div>
                <div class="item-value">{{ summary.age }}</div>
              </div>
              <div class="item">
                <div class="item-label">手机号</div>
                <div class="item-value">{{ summary.sjh }}</div>
              </div>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item-title">设备信息</div>
            <div class="flex-item-body">
              <div class="item">
                <div class="item-label">传感器唯一码</div>
                <div class="item-value">{{ summary.qrCode }}</div>
              </div>
              <div class="item">
                <div class="item-label">发射器识别码</div>
                <div class="item-value">{{ summary.bleId }}</div>
              </div>
              <div class="item">
                <div class="item-label">发射器电量</div>
                <div class="item-value green">{{ summary.lastPower }}</div>
              </div>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item-title">当前体糖</div>
            <div class="flex-item-body">
              <div class="item">
                <div class="item-label">最新体糖</div>
                <div class="item-value green">{{ summary.lastMachineBloodSugar }}</div>
              </div>
              <div class="item">
                <div class="item-label">最新上传时间</div>
                <div class="item-value red">{{ summary.lastDataBagTime }}</div>
              </div>
              <div class="item">
                <div class="item-label">数据时长</div>
                <div class="item-value green">{{ summary.jcts }}</div>
              </div>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item-title">监测信息</div>
            <div class="flex-item-body">
              <div class="item">
                <div class="item-label">开始时间</div>
                <div class="item-value red">{{ summary.startDate }}</div>
              </div>
              <div class="item">
                <div class="item-label">佩戴时长</div>
                <div class="item-value green">{{ summary.pdsc }}</div>
              </div>
              <div class="item">
                <div class="item-label">监测状态</div>
                <div class="item-value item-value-wrapper" :style="{
                  backgroundColor: summary.jczt_color
                }">
                  {{ summary.jczt }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item" style="width: 480px; flex: none">
            <div class="flex-item-title flex" style="justify-content: space-between;align-items: center;">
              <span>今日体糖分析</span>
              <DatePicker
                style="font-size: 18px;"
                type="date"
                :value="today"
                @on-change="handleDateChange"
                format="yyyy-MM-dd">
              </DatePicker>
            </div>
            <div class="flex-item-body" style="height: calc(100% - 54px);">
              <div style="height: 100%;" ref="pieChart"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom">体糖曲线</h2>
        <div class="content-info">
          <span>顾客体糖范围TIR：<span class="content-info-value">{{ summary.startSugar }} - {{ summary.endSugar }}</span></span>
          <span>监测开始时间：<span class="content-info-value">{{ summary.startDate }}</span></span>
          <span>体糖数据时长：<span class="content-info-value">{{ summary.jcts }}</span></span>
          <Select style="width:200px" @on-change="getChartsData">
            <Option value="2">Day 2</Option>
            <Option value="3">Day 3</Option>
            <Option value="4">Day 4</Option>
            <Option value="5">Day 5</Option>
            <Option value="6">Day 6</Option>
            <Option value="7">Day 7</Option>
            <Option value="8">Day 8</Option>
            <Option value="9">Day 9</Option>
          </Select>
        </div>
        <div class="chart-panel" v-for="(dayItem, index) in daysData" :key="index">
          <div class="chart-header">
            <div class="chart-header-item">
              <div class="chart-date">{{dayItem.day}}</div>
              <div><span>日期：{{dayItem.rq}}</span>距离正常期时间差值：{{dayItem.jlaqsjcz}}</div>
            </div>
            <div class="chart-header-item grey">代谢紊乱（结构受损）时长：{{dayItem.sjtj.jgss}}</div>
            <div class="chart-header-item red">代谢紊乱（功能受损）时长：{{dayItem.sjtj.ngss}}</div>
            <div class="chart-header-item green">正常期时长：{{dayItem.sjtj.zcq}}</div>
            <div class="chart-header-item yellow">调节受损期时长：{{dayItem.sjtj.tjss}}</div>
          </div>
          <div class="chart-holder" :ref="`echart${index}`"></div>
          <div class="chart-footer">
            <div class="chart-header-item">
              MAX: {{dayItem.area_pjz.area_0_6.max}}
              MIN: {{dayItem.area_pjz.area_0_6.min}}
              AVG: {{dayItem.area_pjz.area_0_6.pjz}}
            </div>
            <div class="chart-header-item">
              MAX: {{dayItem.area_pjz.area_6_12.max}}
              MIN: {{dayItem.area_pjz.area_6_12.min}}
              AVG: {{dayItem.area_pjz.area_6_12.pjz}}
            </div>
            <div class="chart-header-item">
              MAX: {{dayItem.area_pjz.area_12_18.max}}
              MIN: {{dayItem.area_pjz.area_12_18.min}}
              AVG: {{dayItem.area_pjz.area_12_18.pjz}}
            </div>
            <div class="chart-header-item">
              MAX: {{dayItem.area_pjz.area_18_24.max}}
              MIN: {{dayItem.area_pjz.area_18_24.min}}
              AVG: {{dayItem.area_pjz.area_18_24.pjz}}
            </div>
          </div>
        </div>
        <div class="content-body">
          <Table :columns="columns" :data="resultData" :height="300"></Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import servicesService from '@/services/servicesService';
import dayjs from 'dayjs';
import echarts from 'echarts';

export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			columns: [
				{ title: '包号', key: 'bh', align: 'center' },
				{ title: '体糖值（mmol/L）', key: 'xtz', align: 'center' },
				{ title: '时间', key: 'sj', align: 'center' },
				{
					title: '异常类型',
					key: 'yclx',
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								style: {
									color:
										params.row.yclx === '体糖超标'
											? '#BB0E0E'
											: params.row.yclx === '体糖正常'
												? '#4abb39'
												: '#DFA200',
								},
							},
							params.row.yclx,
						);
					},
				},
				{
					title: '行为',
					key: 'xw',
					align: 'center',
					filters: [
						{ label: '饮食', value: '饮食' },
						{ label: '锻炼', value: '锻炼' },
						{ label: '参比', value: '参比' },
						{ label: '用药', value: '用药' },
					],
					filterMethod(value, row) {
						return row.xw === value;
					},
				},
				{ title: '行为时间', key: 'xwsj', align: 'center' },
				{ title: '内容', key: 'nr', align: 'center' },
				{ title: '持续时间', key: 'cxsj', align: 'center' },
				{
					title: '操作',
					align: 'center',
					render: (h) => {
						return h(
							'a',
							{
								attrs: {
									target: '_blank',
									href: `/#/base/member/detail/${this.$route.params.uid}/0?type=blood`,
								},
								style: {
									margin: '0 10px',
									color: '#ff8400',
								},
							},
							'咨询',
						);
					},
				},
			],
			summary: {},
			resultData: [],
			today: '',
			startSugar: '',
			endSugar: '',
			daysData: [],
		};
	},
	mounted() {
		this.today = dayjs().format('YYYY-MM-DD');
		const params = { snapshotId: this.$route.params.snapshotId };
		servicesService.MQBloodSugarSummary(params).then((data) => {
			this.summary = data;
		});
		servicesService.MQBloodSugarAnalysis({ ...params, day: this.today }).then((data) => {
			if (data) this.createPieChart(data);
		});
		servicesService.MQBloodSugarCurve(params).then((data) => {
			this.resultData = data.table_data;
		});
		this.getChartsData();
	},
	methods: {
		handleDateChange(value) {
			const params = { snapshotId: this.$route.params.snapshotId, day: value };
			servicesService.MQBloodSugarAnalysis(params).then((data) => {
				if (data) this.createPieChart(data);
			});
		},
		createLineCharts(chartData) {
			chartData.forEach((chartValue, index) => {
				const data = chartValue.qxsj;
				const chartData = [];
				const barChartData = [];
				const barChartHolderData = [];
				const markPointData = [];
				for (let i = 0, len = data.length; i < len; i++) {
					const item = data[i];
					if (item.machineBloodSugar > 0) {
						chartData.push([item.recordDate, item.machineBloodSugar]);
					}
					if (item.eventList.length > 0) {
						for (let j = 0, l = item.eventList.length; j < l; j++) {
							const eventType = item.eventList[j].eventType;
							if (eventType === 1) {
								const value = [item.recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/canbi.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 2) {
								const value = [item.recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/yinshi.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 3) {
								const value = [item.recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol: 'image://' + new URL('/src/assets/img/yongyao.png', import.meta.url).href,
									symbolSize: 18,
								});
							} else if (eventType === 5) {
								const value = [item.recordDate, item.machineBloodSugar];
								markPointData.push({
									coord: value,
									symbol:
										'image://' + new URL('/src/assets/img/duanlian.png', import.meta.url).href,
									symbolSize: 18,
								});
							}
						}
					}
					barChartData.push([
						item.recordDate,
						Math.abs(item.machineBloodSugar - 5),
						item.machineBloodSugar,
					]);
					barChartHolderData.push([
						item.recordDate,
						item.machineBloodSugar - 5 >= 0 ? 5 : item.machineBloodSugar,
					]);
				}
				const xAxisMarkLine = new Array(4).fill(null).map((item, index) => {
					return {
						xAxis: `${chartValue.rq} ${6 * (index + 1)}:00`,
						lineStyle: { color: '#ff7171', width: 2 },
					};
				});
				echarts.init(this.$refs[`echart${index}`][0]).setOption({
					grid: {
						top: 40,
						left: 25,
						right: 25,
						bottom: 40,
					},
					animation: false,
					xAxis: {
						type: 'time',
						axisTick: { show: false },
						axisLine: {
							lineStyle: {
								color: '#ff7171',
							},
						},
						splitLine: false,
						interval: 60 * 60 * 1000,
					},
					yAxis: {
						name: 'mmol/L',
						type: 'value',
						splitLine: {
							show: false,
						},
						axisLine: {
							lineStyle: {
								color: '#ff7171',
							},
						},
						min: 2,
					},
					series: [
						{
							name: '体糖',
							type: 'line',
							data: chartData,
							symbol: 'none',
							itemStyle: {
								normal: {
									lineStyle: {
										color: '#0f85f3',
									},
								},
							},
							markPoint: {
								symbolSize: 30,
								data: markPointData,
							},
							markLine: {
								label: { show: false },
								symbol: 'none',
								itemStyle: { color: '#999' },
								data: [
									{
										yAxis: 3.0,
									},
									{
										yAxis: 3.9,
									},
									{
										yAxis: 4.4,
									},
									{
										yAxis: 6.1,
									},
									{
										yAxis: 7.0,
									},
									{
										yAxis: 7.8,
									},
									{
										yAxis: 11.1,
									},
									{
										yAxis: 5,
										lineStyle: { color: '#47e1b9', type: 'solid' },
									},
									...xAxisMarkLine,
								],
								silent: true,
							},
						},
						{
							name: '透明',
							stack: '总量',
							type: 'bar',
							data: barChartHolderData,
							itemStyle: {
								color: 'rgba(0,0,0,0)',
							},
						},
						{
							name: '结果',
							stack: '总量',
							type: 'bar',
							data: barChartData,
							itemStyle: {
								color: function (params) {
									const value = params.value[2];
									if (value < 4.4) {
										return '#f34141';
									} else if (value < 6.1) {
										return '#3ea40c';
									} else if (value < 7.8) {
										return '#fab02a';
									} else if (value < 11.1) {
										return '#f34141';
									} else {
										return '#383737';
									}
								},
								barBorderRadius: 20,
							},
						},
					],
				});
			});
		},
		createPieChart(data) {
			echarts.init(this.$refs.pieChart).setOption({
				color: ['#3ea40c', '#fab02a', '#f34141', '#383737'],
				series: [
					{
						name: '体糖占比',
						type: 'pie',
						radius: '70%',
						data: [
							{ value: data.zc_xt, name: '正常期' },
							{ value: data.pg_xt, name: '调节受损期' },
							{ value: +data.d_xt + +data.g_xt, name: '代谢紊乱\n(功能受损)' },
							{ value: data.jg_xt, name: '代谢紊乱\n(结构受损)' },
						],
						label: {
							formatter: (params) => {
								return `${params.name}\n${params.percent} %`;
							},
							fontSize: 14,
						},
						animation: false,
					},
				],
			});
		},
		getChartsData(val) {
			const params = { snapshotId: this.$route.params.snapshotId };
			if (val) params.day = val;
			servicesService.MQDetailCharts(params).then((data) => {
				this.daysData = data.map((item) => item.qx_data);
				this.$nextTick(() => {
					this.createLineCharts(this.daysData);
				});
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-panel {
  background-color: #fff;
  margin-bottom: 20px;
}
.chart-holder {
  height: 260px;
}
.flex {
  display: flex;
  .flex-item {
    flex: 1;
    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }
    &-title {
      height: 54px;
      line-height: 54px;
      padding: 0 20px;
      font-size: 22px;
      border-bottom: 1px solid #ddd;
    }
    &-body {
      padding: 10px 20px;
    }
  }
}
.item {
  &-label {
    font-size: 20px;
    margin-bottom: 5px;
  }
  &-value {
    font-size: 22px;
    font-weight: 700;
    &.red {
      color: #950000;
    }
    &.green {
      color: #1aaa04;
    }
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &-value-wrapper {
    border-radius: 4px;
    color: #fff;
    padding: 5px 20px;
    display: inline-block;
  }
}

.content-info {
  padding: 10px 20px;
  font-size: 20px;
  &-value {
    color: #0f85f3;
    margin-right: 30px;
  }
}
.chart-panel {
  padding: 0 20px;
  margin-bottom: 20px;
  font-size: 20px;
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 25px;
    .chart-header-item:first-child {
      width: 500px;
    }
    .chart-date {
      font-size: 20px;
      font-weight: bold;
    }
    span {
      margin-right: 20px;
    }
  }
  .chart-footer {
    display: flex;
    font-weight: bold;
    margin: 0 50px;
    justify-content: space-around;
  }
  .grey {
    color: #383737;
  }
  .red {
    color: #F34141;
  }
  .green {
    color: #3ea40c;
  }
  .yellow {
    color: #fab02a;
  }
}
</style>